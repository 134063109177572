export const IntakePaperworkStatus = {
  NOT_SENT: 'Not Sent',
  SENT: 'Sent',
  COMPLETED: 'Completed',
  UPLOADED: 'Uploaded'
}

export const IntakePaperworkStatusOptions = [
  IntakePaperworkStatus.NOT_SENT,
  IntakePaperworkStatus.SENT,
  IntakePaperworkStatus.COMPLETED,
  IntakePaperworkStatus.UPLOADED
]
