
export const CaseStatuses = {
  ACTIVE: { text: 'Active', value: 'active' },
  INTAKE: { text: 'Intake', value: 'intake' },
  DISCHARGED: { text: 'Discharged', value: 'discharged' },
  BILLED: { text: 'Billed', value: 'billed' },
  SETTLED: { text: 'Settled', value: 'settled' },
  COLLECTED: { text: 'Collected', value: 'collected' },
  BAD: { text: 'Bad', value: 'bad' }
}

export const CaseStatusOptions = [
  CaseStatuses.ACTIVE,
  CaseStatuses.INTAKE,
  CaseStatuses.DISCHARGED,
  CaseStatuses.BILLED,
  CaseStatuses.SETTLED,
  CaseStatuses.COLLECTED,
  CaseStatuses.BAD
]

export const NoTransitionCaseStatuses = [
  CaseStatuses.BAD,
  CaseStatuses.COLLECTED
]

export const NoAppointmentCaseStatuses = [
  CaseStatuses.BAD.value,
  CaseStatuses.BILLED.value,
  CaseStatuses.SETTLED.value,
  CaseStatuses.COLLECTED.value
]
