import event, { Events } from '@/event'
import { mapActions, mapGetters, mapState } from 'vuex'
import { capitalizeFirstWord } from '@/js/functions'

export default {
  mixins: [],
  mounted () {
    this.getCases()
  },
  data: () => ({
    back: false,
    options: {
      page: 1,
      itemsPerPage: 25,
      sortBy: 'created_at',
      sortDesc: ['desc']
    },
    search: null
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl',
      activeLocationId: 'User/activeLocationId',
      caseSearchParams: 'User/getCaseSearchParams'
    }),
    ...mapState({
      cases: state => state.Case.cases,
      casesCount: state => state.Case.casesCount
    })
  },
  methods: {
    ...mapActions('User', ['setCaseSearchParams']),
    capitalizeFirstWord,
    updateSearchInput (valueFromChild) {
      this.search = valueFromChild
    },
    getCases (options) {
      this.$store.commit('loading', true)

      if (options && typeof (options) === 'object') {
        this.options = options
      }

      if (this.search) {
        this.search = this.search.toString().toLowerCase()
      }

      const sort = (
        this.options.sortDesc.length === 0 ||
          this.options.sortDesc[0] === false ||
          this.options.sortDesc[0] === 'asc'
      ) ? 'asc' : 'desc'

      this.filterParams.search = this.search
      this.filterParams.page = this.options.page
      this.filterParams.limit = this.options.itemsPerPage
      this.filterParams.orderBy = this.options.sortBy || null
      this.filterParams.orderDir = sort
      this.filterParams.activeLocationId = this.activeLocationId
      this.filterParams.intakePaperworkStatus = this.paperworkStatus

      // Need to set the state to the search params for toggling of active location
      this.setCaseSearchParams(this.filterParams)

      event.emit(Events.LOADING, true)

      this.$store
        .dispatch('Case/getCases', this.filterParams)
        .then(() => {
          this.$store.commit('loading', false)
        }).finally(() => {
          event.emit(Events.LOADING, false)
        })
    },
    goToCase (caseId) {
      this.$router.push({ name: 'CaseView', params: { caseId: caseId.toString() } })
    }
  },
  watch: {
    search: {
      handler: function () {
        this.getCases()
      },
      deep: true
    }
  }
}
