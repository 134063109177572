export function capitalizeFirstWord (word) {
  if (!word) {
    return ''
  }
  return word[0].toUpperCase() + word.slice(1)
}

export function convertPenniesToDecimals (money) {
  if (!money) {
    return ''
  }

  return '$ ' + money / 100
}
