<template>
  <v-form v-model="formValid" ref="form">
    <v-select
      label="Location"
      v-model="appointment.location_id"
      :items="locations"
      item-value="id"
      item-text="name"
      filled
      :rules="[rules.required]"
    />
    <v-select
      :items="treatmentOptions"
      label="Treatment"
      v-model="appointment.treatment_id"
      :rules="[rules.required]"
      filled />
    <date-picker
      ref="datepicker"
      :date="appointment.date"
      :default-to-today="false"
      label="Date"
      @date-change="setDate"
      :dateValid="dateValid"
      :rules="[rules.required]"
    />
    <v-row>
      <v-col cols="8">
        <v-text-field
          v-model="appointment.time"
          label="Time"
          v-mask="'##:##'"
          hint="format hh:mm"
          filled prepend-inner-icon="fa-clock"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="4">
        <v-select :items="['AM', 'PM']" v-model="appointment.day" filled :rules="[rules.required]" />
      </v-col>
    </v-row>
    <v-textarea v-model="appointment.note" filled label="Appointment Notes" />
  </v-form>
</template>
<script>
import DatePicker from '@/components/PatientIntake/DatePicker'
import { mapActions, mapGetters } from 'vuex'
import rulesMixin from '@/mixins/RulesMixin'
import DateMixin from '@/mixins/DateMixin'

export default {
  name: 'AppointmentForm',
  components: { DatePicker },
  mixins: [rulesMixin, DateMixin],
  props: {
    passedAppointment: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    dialog: false,
    formValid: true,
    dateValid: true,
    appointment: {
      location_id: '',
      treatment_id: '',
      date: '',
      time: '',
      day: '',
      note: ''
    }
  }),
  created () {
    this.getLocations()
    this.getTreatments()
  },
  mounted () {
    this.appointment = this.passedAppointment
    this.$nextTick(() => {
      this.$refs.datepicker.checkDate()
    })
  },
  computed: {
    ...mapGetters({
      treatmentOptions: 'Treatments/treatmentSelectOptions',
      locations: 'Organization/locations'
    })
  },
  methods: {
    ...mapActions({
      getLocations: 'Organization/getLocations',
      getTreatments: 'Treatments/getTreatments'
    }),
    setDate (value) {
      this.appointment.date = value
    },
    validate () {
      this.$refs.form.validate()
      this.validateDate()
    },
    validateDate () {
      this.dateValid = true
      if (!this.appointment.date) {
        this.dateValid = 'Required'
      }
    },
    reset () {
      this.dateValid = true
      this.$refs.form.reset()
      this.appointment = {
        location_id: '',
        treatment_id: '',
        date: '',
        time: '',
        day: '',
        note: ''
      }
    }
  },
  updated () {
    this.$emit('updated', this.appointment)
  }
}
</script>
