<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="600" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>Create New Appointment</v-card-title>
      <v-card-text>
        <AppointmentForm :passedAppointment="appointment" ref="appointmentForm" @updated="reassignAppointment"/>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn @click="cancel">Cancel</v-btn>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn depressed color="primary" @click="createAppointment">Save</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import event, { Events } from '@/event'
import AppointmentForm from '@/components/Case/Forms/AppointmentForm.vue'
import { NoTransitionCaseStatuses, CaseStatuses } from '@/js/CaseStatuses'

export default {
  name: 'CreateAppointmentFormDialog',
  components: { AppointmentForm },
  mixins: [],
  data: () => ({
    dialog: false,
    appointment: {
      location_id: '',
      treatment_id: '',
      date: '',
      time: '',
      day: '',
      note: ''
    },
    caseId: '',
    caseStatus: '',
    NoTransitionCaseStatuses,
    CaseStatuses
  }),
  computed: {},
  methods: {
    open (id, status) {
      this.caseId = id
      this.caseStatus = status
      this.dialog = true
    },
    cancel () {
      this.resetAppointment()
      this.dialog = false
      this.$emit('reload')
    },
    reassignAppointment (value) {
      this.appointment = value
    },
    async createAppointment () {
      this.$refs.appointmentForm.validate()

      if (!this.$refs.appointmentForm.formValid || this.$refs.appointmentForm.dateValid === 'Required') {
        return
      }

      try {
        await window.axios.post(this.$store.getters['Organization/apiUrl'] + '/case/' + this.caseId + '/appointments', {
          appointment: this.appointment
        }).then(async () => {
          await this.checkForCaseTransition()
          event.emit(Events.SUCCESS, 'Appointment created successfully')
          this.$emit('reload')
          this.cancel()
        })
      } catch (error) {
        event.emit(Events.ERROR, error.response.data.payload.message, 2000)
      }
    },
    resetAppointment () {
      this.caseId = ''
      this.caseStatus = ''
      this.appointment = {
        location_id: '',
        treatment_id: '',
        date: '',
        time: '',
        day: '',
        note: ''
      }
      this.$refs.appointmentForm.reset()
    },
    async checkForCaseTransition () {
      if (!this.caseStatus) {
        console.log('create appointment dialog check transition now case status')
        return
      }

      // skip bad or collected cases
      if (NoTransitionCaseStatuses.includes(this.caseStatus)) {
        return
      }

      // created appointments for intake or active will no transition the case
      if (this.caseStatus === CaseStatuses.INTAKE.value || this.caseStatus === CaseStatuses.ACTIVE.value) {
        return
      }

      // only cases that are discharged, billed or settled can be transitioned back to active
      try {
        await this.$store.dispatch('Case/transitionCase', {
          caseId: this.caseId,
          transition: CaseStatuses.ACTIVE.value
        })
      } catch (error) {
        event.emit(Events.ERROR, 'Case could not transition to active', 2000)
      }
    }
  }
}
</script>
